import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import Text from './Text'

type Props = {
  color?: 'blueDark' | 'blueLight' | 'blueMedium' | 'greenDark' | 'pink' | 'transparent' | 'yellow' | 'grey' | 'white'
  itemsCenter?: true
  title?: { index?: number; label: string }
}

export default function Section(props: PropsWithChildren<Props>) {
  if (!props.title) {
    return (
      <div
        className={classNames('p-2', {
          'bg-blueDark': props.color === 'blueDark',
          'bg-blueLight': props.color === 'blueLight',
          'bg-blueMedium': props.color === 'blueMedium',
          'bg-greenDark': props.color === 'greenDark',
          'bg-pink': props.color === 'pink',
          'bg-yellow': props.color === 'yellow',
          'bg-greyLight': props.color === 'grey',
          'bg-white': props.color === 'white',
          'flex flex-col items-center': props.itemsCenter,
        })}
      >
        {props.children}
      </div>
    )
  }

  return (
    <>
      <div className="bg-yellow flex py-2 px-5">
        <div className="flex flex-col justify-center">
          {props.title.index && (
            <div className="bg-yellowLight font-semibold text-2xl px-3 py-1 mr-3 rounded-md">{props.title.index}</div>
          )}
        </div>

        <div className="flex flex-col justify-center">
          <Text>{props.title.label}</Text>
        </div>
      </div>

      <div
        className={classNames('p-6', {
          'flex flex-col items-center': props.itemsCenter,
        })}
      >
        {props.children}
      </div>
    </>
  )
}
