import { createAsyncThunk } from '@reduxjs/toolkit'
import { Extra, RootState } from '../../redux/store'
import { Subscription } from '../../types/Subscription'
import { UpdateSubscriberParams } from '../../updateParams/updateSubscriberParams'

export const updateSubscriber = createAsyncThunk<Subscription, UpdateSubscriberParams, Extra>(
  'subscription/updateSubscriber',
  async (params, thunkApi) => {
    const subscription = (thunkApi.getState() as RootState).subscription

    if (!subscription) {
      throw new Error('Susbcription not loaded')
    }

    return thunkApi.extra.appApi.updateSubscriber(params)
  }
)
