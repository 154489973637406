import Image from 'next/image'
import imageLoader from './loader.gif'

export default function Loader() {
  return (
    <div className="flex flex-col items-center">
      <Image alt="Loader" height={291} priority={true} src={imageLoader} width={441} />
    </div>
  )
}
