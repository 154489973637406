import { InAppProduct } from '../types/in-app/InAppProduct';

type TrackingEvents =
  | { name: 'Réalise son devis' } // Home
  | { name: 'consulte_detail_formule'; gamme: 'mybooster'; produit: string } // Home
  | { name: 'Demande une documentation'; source: 'Home' | 'Simulation' } // Home
  | { name: 'A sélectionné les bénéficiaires' } // Step 1 - Check beneficiaries
  | { name: 'A validé le devoir de conseil' } // Step 2 - Care needs
  | { name: 'Valide une offre' } // Step 3 - Select offer
  | { name: 'Souscrit à une offre' } // Step 4 - Review offer
  | { name: 'Valide le formulaire'; source: 'Souscripteur' | 'Bénéficiaire' } // Step 5 - Valide le formulaire
  | { name: 'A renseigné les informations bancaires' } // Step 6 - Bank details
  | { name: 'A signé son contrat' } // Step 7 - Signature

type StepChangeEvents =
  | 'step_changed_Besoin'
  | 'step_changed_Dossier'
  | 'step_changed_Offres'
  | 'step_changed_Signature'
  | 'step_changed_Fin'

type PostMessageEvent =
  | { type: 'open_modal'; data: string }
  | { type: 'tracking'; data: TrackingEvents }
  | { type: StepChangeEvents; data: undefined }
  | { type: 'downloadApp'; data: undefined }
  | { type: 'resize'; data: number }

export const postMessageStepChanged = (step: StepChangeEvents) => postIframeMessage({ type: step, data: undefined })

export const postMessageTrack = (event: TrackingEvents) => postIframeMessage({ type: 'tracking', data: event })

export const postMessageOpenProductModal = (product: InAppProduct) =>
  postIframeMessage({ type: 'open_modal', data: product.id })

export const postMessageDownloadApp = () => postIframeMessage({ type: 'downloadApp', data: undefined })

const postIframeMessage = (message: PostMessageEvent) => {
  window.parent.postMessage({ eventType: message.type, data: message.data }, '*')
}

