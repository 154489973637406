import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit'
import { AppApiInterface } from './services/AppApiInterface'
import discountReducer from './reducers/discountReducer'
import subscriptionReducer from './reducers/subscriptionReducer'

const reducer = combineReducers({
  discount: discountReducer.reducer,
  subscription: subscriptionReducer.reducer,
})

export type RootState = ReturnType<typeof reducer>

export type Extra = {
  extra: {
    appApi: AppApiInterface
  }
}

type Params = {
  initialState?: RootState
  appApi: AppApiInterface
}

// Catch all errors that occur in the reducers such as rejected promises
export const throwMiddleware: Middleware = () => (next) => (action) => {
  next(action)
  if (action?.error) {
    console.error('Redux action error:', action)
    throw new Error(
      JSON.stringify({
        action: action.type,
        error: action.error.name,
        message: action.error.message,
      })
    )
  }
}

export const createStore = (params: Params) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            appApi: params.appApi,
          },
        },
      }).concat(throwMiddleware),
    preloadedState: params.initialState,
    reducer,
  })

export type AppStore = ReturnType<typeof createStore>

export type AppDispatch = AppStore['dispatch']

let store: AppStore

export const getOrCreateStore = (params: Params) => {
  if (store === undefined) {
    store = createStore(params)
    return store
  }

  return store
}
