import { createSlice } from '@reduxjs/toolkit'
import { fetchSubscriptionData } from '../../core/useCases/fetchSubscriptionData'
import updateCareNeeds from '../../core/useCases/updateCareNeeds'
import updateStep3SelectOffer from '../../core/useCases/updateStep3SelectOffer'
import { updateStep4EffectiveDate } from '../../core/useCases/updateStep4EffectiveDate'
import { updateBankDetails } from '../../core/useCases/updateBankDetails'
import { updateSubscriber } from '../../core/useCases/updateSubscriber'
import { updateBeneficiary } from '../../core/useCases/updateBeneficiary'
import { Subscription } from '../../types/Subscription'
import { updateSignatureId } from '../../core/useCases/updateSignatureId'
import { checkBeneficiary } from '../../core/useCases/checkBeneficiary'

// If the initial state is empty, it muste be null instead of undefined
const initialState: Subscription | null = null as Subscription | null

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // TODO should we update all the fields after a fetch?
    builder
      .addCase(fetchSubscriptionData.fulfilled, (state, { payload }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { discount, ...subscription } = payload
        return subscription
      })
      .addCase(checkBeneficiary.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.beneficiaries = payload.beneficiaries
      })
      .addCase(updateCareNeeds.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.careNeeds = payload.careNeeds
      })
      .addCase(updateStep3SelectOffer.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.productId = payload.productId
      })
      .addCase(updateStep4EffectiveDate.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.effectiveDate = payload.effectiveDate
      })
      .addCase(updateBankDetails.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.iban = payload.iban
      })
      .addCase(updateBeneficiary.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.beneficiaries = payload.beneficiaries
        state.beneficiariesValid = payload.beneficiariesValid
      })
      .addCase(updateSubscriber.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.subscriber = payload.subscriber
        state.beneficiariesValid = payload.beneficiariesValid
        state.subscriberValid = payload.subscriberValid
      })
      .addCase(updateSignatureId.fulfilled, (state, { payload }) => {
        if (!state) {
          throw new Error('The subscription is not initialized')
        }

        state.signatureId = payload.signatureId
      })
  },
})

export const subscriptionActions = subscriptionSlice.actions

const defaultExports = { reducer: subscriptionSlice.reducer }
export default defaultExports
