import { NextIntlProvider } from 'next-intl'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { fetchSubscriptionData } from '../../../core/useCases/fetchSubscriptionData'
import messages from '../../../public/i18n/fr.json'
import { AppApi } from '../../../redux/services/AppApi'
import { createStore, getOrCreateStore } from '../../../redux/store'
import { AppContext } from '../../AppContext'
import { Crisp } from '../../Crisp'
import Heading from '../../Heading'
import Section from '../../Section'
import Text from '../../Text'
import Loader from '../../loader/Loader'

const DUMMY_TOKEN = 'dummy-token'

const createApiAndStore = (token: string) => {
  if (token === DUMMY_TOKEN) {
    // We create a dummy store, just to have something to pass to the Provider, then create the real store once we have
    // the token
    const appApi = new AppApi({ id: DUMMY_TOKEN })
    return { appApi, store: createStore({ appApi }) }
  }

  const appApi = new AppApi({ id: token })
  return { appApi, store: getOrCreateStore({ appApi }) }
}

const useAppStore = () => {
  const router = useRouter()

  const [isTokenMissing, setIsTokenMissing] = useState(false)
  const [token, setToken] = useState<string>(DUMMY_TOKEN)
  const { appApi, store } = createApiAndStore(token)

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const routerToken = router.query.externalId

    if (typeof routerToken === 'string') {
      // A token was found in the router, save it to the local storage and use it
      localStorage.setItem('token', routerToken)
      setToken(routerToken)
    }

    // If no token is found in the router, use the one from local storage.
    const localStorageToken = localStorage.getItem('token')

    if (!localStorageToken) {
      //    The local storage is empty as well, we're mising a token and we can't continue.
      console.log('no local storage token')
      setIsTokenMissing(true)
      return
    }

    // Use the token from localStorage
    setToken(localStorageToken)
  }, [router, router.isReady, router.query.token, token])

  useEffect(() => {
    if (token === DUMMY_TOKEN) {
      return
    }

    // Persist the token so we don't have to pass it page to page
    localStorage.setItem('token', token)

    // The token is now available, we can populate the store
    store.dispatch(fetchSubscriptionData())
  }, [token, store])

  return { appApi, store, token, isTokenMissing }
}

export default function InAppLayout(props: PropsWithChildren) {
  const { isTokenMissing, token, appApi, store } = useAppStore()

  const appContext = {
    appApi,
  }

  const isLoading = token === DUMMY_TOKEN

  if (isTokenMissing) {
    return (
      <Section color="pink">
        <Heading align="center" color="white">
          Une erreur est survenue
        </Heading>
        <Text color="white">Nous faisons tout notre possible pour y rémédier.</Text>
        <Text color="white">Veuillez réessayer plus tard.</Text>
      </Section>
    )
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <NextIntlProvider locale="fr" messages={messages}>
      <AppContext.Provider value={appContext}>
        <Provider store={store}>
          {/* <InAppChatBot /> */}
          <Crisp />
          <div className="flex flex-col items-center bg-white">
            <main className="bg-white min-h-screen max-w-3xl w-full">{props.children}</main>
          </div>
        </Provider>
      </AppContext.Provider>
    </NextIntlProvider>
  )
}
