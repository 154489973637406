import classNames from 'classnames'
import { PropsWithChildren } from 'react'

type Props = {
  color?: 'white'
  level?: 1 | 2
  align?: 'center'
}

const Heading = (props: PropsWithChildren<Props>) => {
  const classes = classNames('mb-3 px-3 leading-9', {
    'text-white': props.color === 'white',
    'text-center': props.align === 'center',
  })

  if (props.level === 2) {
    return <h2 className={classNames(classes, 'font-semibold text-2xl')}>{props.children}</h2>
  }

  return <h1 className={classNames(classes, 'font-bold text-3xl')}>{props.children}</h1>
}

export default Heading
