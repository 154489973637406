import classNames from 'classnames'
import { PropsWithChildren } from 'react'

type Props = {
  align?: 'center' | 'right'
  as?: 'span'
  color?: string
  lineThrough?: true
  size?: 10 | 13 | 14 | 15 | 18 | 20 | 24 | 26 | 33 | 60
  weight?: 'light' | 'medium' | 'bold' | 'extrabold' | 'semibold'
  fontStyle?: 'italic'
}

const Text = (props: PropsWithChildren<Props>) => {
  const classes = classNames({
    [`text-${props.color}`]: !props.color?.startsWith('#') ? props.color : undefined, // Color by name

    'text-[10px] leading-4': props.size === 10,
    'text-[13px]': props.size === 13,
    'text-[14px]': props.size === 14,
    'text-[15px]': props.size === 15,
    'text-[18px]': props.size === 18,
    'text-[20px]': props.size === 20,
    'text-2xl': props.size === 24,
    'text-[26px]': props.size === 26,
    'text-[33px]': props.size === 33,
    'text-[60px]': props.size === 60,

    'text-center': props.align === 'center',
    'text-right': props.align === 'right',

    'font-light': props.weight === 'light', // 300
    'font-medium': props.weight === 'medium', // 500
    'font-semibold': props.weight === 'semibold', // 600
    'font-bold': props.weight === 'bold', // 700
    'font-[800]': props.weight === 'extrabold',

    italic: props.fontStyle === 'italic',

    'line-through': props.lineThrough,
  })

  const style = {
    color: props.color?.startsWith('#') ? props.color : undefined, // Color by hex
  }

  if (props.as === 'span') {
    return (
      <span className={classes} style={style}>
        {props.children}
      </span>
    )
  }

  return (
    <div className={classes} style={style}>
      {props.children}
    </div>
  )
}

export default Text
