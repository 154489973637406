import { createContext, useContext } from 'react'
import { AppApiInterface } from '../redux/services/AppApiInterface'

type AppContextInterface = {
  appApi: AppApiInterface
}

export const AppContext = createContext<AppContextInterface | undefined>(undefined)

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}
