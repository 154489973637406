const routes = {
  index: '/',
  step1SelectBeneficiaries: '/in-app/1-select-beneficiaries',
  step2: {
    careNeeds: '/in-app/2-care-needs',
    cutOff: '/in-app/2-care-needs/cut-off',
  },
  step3SelectProduct: '/in-app/3-select-offer',
  step4ReviewOffer: '/in-app/4-review-offer',
  step5BeneficiariesDetails: {
    first: '/in-app/5-beneficiaries-details/1',
    person: (personIndex: number) => `/in-app/5-beneficiaries-details/${personIndex}`,
  },
  step6PaymentDetails: '/in-app/6-bank-details',
  step7Signature: '/in-app/7-signature',
  step8ThankYou: '/in-app/8-thank-you',
}

export default routes
