import docEnVille50Svg from '../assets/products/in-app/docEnVille50.svg'
import docEnVille75Svg from '../assets/products/in-app/docEnVille75.svg'
import docEtDent75Svg from '../assets/products/in-app/docEtDent75.svg'
import docEnVille50WebSvg from '../assets/products/web/icons/docEnVille50.svg'
import docEnVille75WebSvg from '../assets/products/web/icons/docEnVille75.svg'
import docEtDent75WebSvg from '../assets/products/web/icons/docEtDent75.svg'
import svgWebProductCardClosedDocEnVille50 from '../assets/products/web/product-cards/closed/backgroundClosedDocEnVille50.svg'
import svgWebProductCardClosedDocEnVille75 from '../assets/products/web/product-cards/closed/backgroundClosedDocEnVille75.svg'
import svgWebProductCardClosedDocEtDent75 from '../assets/products/web/product-cards/closed/backgroundClosedDocEtDent75.svg'
import docEnVille50Exemple from '../assets/products/web/product-cards/details/docEnVille50Details.svg'
import docEnVille75Exemple from '../assets/products/web/product-cards/details/docEnVille75Details.svg'
import docEtDent75Exemple from '../assets/products/web/product-cards/details/docEtDent75Details.svg'
import svgWebProductCardOpenedDocEnVille50 from '../assets/products/web/product-cards/opened/backgroundOpenedDocEnVille50.svg'
import svgWebProductCardOpenedDocEnVille75 from '../assets/products/web/product-cards/opened/backgroundOpenedDocEnVille75.svg'
import svgWebProductCardOpenedDocEtDent75 from '../assets/products/web/product-cards/opened/backgroundOpenedDocEtDent75.svg'
import { InAppProduct } from '../types/in-app/InAppProduct'
import { WebProduct } from '../types/web/WebProduct'

export const MAX_EFFECTIVE_DATE_MONTHS = 3

export const BENEFICIARY_MAX_AGE = 120
export const SUBSCRIBER_MIN_AGE = 18

// The path to the folder containing the RIB and bundle
export const DOCUMENTS_FOLDER = 'documents'

// The name of the bundle file sent to Universign
export const DOCUMENTS_BUNDLE_NAME = 'bundle.pdf'
export const DOCUMENTS_BUNDLE_SIGNED_NAME = 'bundle-signed.pdf'

export const PRODUCTS_CONTRACT_REF = '9136'

const informationsPdf = {
  doc: 'mybooster_documentation_2022.pdf',
}

const baseProducts = {
  docEtDent: {
    careValue: 75,
    color: '#31D0AA',
    contractRef: 'D-75',
    id: 'DDENT75_22',
    informationsPdf: informationsPdf.doc,
    isDentalCare: true as const,
    label: 'DocEtDent 75',
    maxReimbursment: 1350,
    price: 1799,
    range: 'DocEtDent' as const,
  },
  docEnVille75: {
    careValue: 75,
    color: '#004CB8',
    contractRef: 'S-75',
    id: 'DOC75_22',
    informationsPdf: informationsPdf.doc,
    isDentalCare: false as const,
    label: 'DocEnVille 75',
    maxReimbursment: 750,
    price: 1099,
    range: 'DocEnVille' as const,
  },
  docEnVille50: {
    careValue: 50,
    color: '#009ADC',
    contractRef: 'S-50',
    id: 'DOC50_22',
    informationsPdf: informationsPdf.doc,
    isDentalCare: false as const,
    label: 'DocEnVille 50',
    maxReimbursment: 500,
    price: 749,
    range: 'DocEnVille' as const,
  },
}

// In app products
export const PRODUCT_DENTAL = {
  ...baseProducts.docEtDent,
  icon: docEtDent75Svg,
  colorDiscount: '#076D55',
}

export const PRODUCTS_NON_DENTAL = [
  {
    ...baseProducts.docEnVille75,
    icon: docEnVille75Svg,
    colorDiscount: '#8CBBFD',
  },
  {
    ...baseProducts.docEnVille50,
    icon: docEnVille50Svg,
    careValue: 50,
    colorDiscount: '#8CBBFD',
  },
]

// Ensure that we only have one dental care product
type InAppProducts = [InAppProduct & { isDentalCare: true }, ...Array<InAppProduct & { isDentalCare: false }>]

// Take care of not changing the order of the products, a lot code in the generated PDFs depends on it
export const IN_APP_PRODUCTS: InAppProducts = [PRODUCT_DENTAL, ...PRODUCTS_NON_DENTAL]

export const PRODUCT_WEB_DENTAL: WebProduct = {
  ...baseProducts.docEtDent,
  icon: docEtDent75WebSvg,
  background: {
    opened: svgWebProductCardOpenedDocEtDent75,
    closed: svgWebProductCardClosedDocEtDent75,
  },
  limits: {
    act: 15000, // 150 eur
    yearly: 135000, // 1350 eur
  },
  exemple: docEtDent75Exemple,
}

export const PRODUCTS_WEB_NON_DENTAL: WebProduct[] = [
  {
    ...baseProducts.docEnVille75,
    icon: docEnVille75WebSvg,
    background: {
      opened: svgWebProductCardOpenedDocEnVille75,
      closed: svgWebProductCardClosedDocEnVille75,
    },
    limits: {
      act: 15000, // 150 eur
      yearly: 75000, // 750 eur
    },
    exemple: docEnVille75Exemple,
  },
  {
    ...baseProducts.docEnVille50,
    icon: docEnVille50WebSvg,
    background: {
      opened: svgWebProductCardOpenedDocEnVille50,
      closed: svgWebProductCardClosedDocEnVille50,
    },
    limits: {
      act: 10000, // 100 eur
      yearly: 50000, // 500 eur
    },
    exemple: docEnVille50Exemple,
  },
]

// Take care of not changing the order of the products, a lot code in the generated PDFs depends on it
export const WEB_PRODUCTS: WebProduct[] = [PRODUCT_WEB_DENTAL, ...PRODUCTS_WEB_NON_DENTAL]

export const PRODUCT_TAX_RATE = 1.2027

export const EXPORT_PAGE_LENGTH = 10

const domain = `${process.env.NEXT_PUBLIC_DOMAIN_RAILS}/mybooster`

export const docs = {
  conditionsGenerales: `${domain}/conditions_generales_22-001.pdf`,
  documentation: `${domain}/mybooster_documentation_2022.pdf`,
  statutsAssociation: `${domain}/statuts_association_ASMS.pdf`,
  productsInformation: (product: InAppProduct | WebProduct) => `${domain}/${product.informationsPdf}`,
}

export const WEB_CONFIRMATION_TEMPLATE = '[MYB GP] Nous vous confirmons votre souscription'
export const INAPP_CONFIRMATION_TEMPLATE = 'Nous vous confirmons votre souscription'

export const CONFIRMATION_EMAIL = {
  attachment: 'Votre Contrat myBooster.pdf',
  sender: {
    email: 'no-reply@mysofie.fr',
    name: 'mySofie',
  },
  subject: 'Confirmation de votre souscription à myBooster',
 // template: '',
}

export const DEVIS_DOCETDENT_EMAIL = {
  sender: {
    email: 'no-reply@mysofie.fr',
    name: 'mySofie',
  },
  subject: 'Votre devis myBooster',
  template: 'MYB GP - Devis DocEtDent75',
}

export const DEVIS_DOCENVILLE75_EMAIL = {
  sender: {
    email: 'no-reply@mysofie.fr',
    name: 'mySofie',
  },
  subject: 'Votre devis myBooster',
  template: 'MYB GP - Devis DocEnVille75',
}

// export const DEVIS_DOCENVILLE50_EMAIL = {
//   sender: {
//     email: 'no-reply@mysofie.fr',
//     name: 'mySofie',
//   },
//   subject: 'Votre devis myBooster',
//   template: 'MYB GP - Devis DocEnVille50',
// }


export const ALLOWED_PHONE_COUNTRIES = [
  'GF', // French Guiana
  'GP', // Guadeloupe
  'MQ', // Martinique
  'NC', // New Caledonia
  'PF', // French Polynesia
  'RE', // Reunion
  'WF', // Wallis and Futuna
  'YT', // Mayotte
]

export const CRISP_WEBSITE_ID = 'ec670f17-62ae-4706-8ff7-4e7cd3227345'

export const MYSOFIE_LOGIN_URL = `${process.env.NEXT_PUBLIC_DOMAIN_RAILS}/mybooster/subscriptions/new`

export const MYSOFIE_SITE_URL = `${process.env.NEXT_PUBLIC_MYSOFIE_SITE_URL}`

export const DOMAIN_URL = `${process.env.NEXT_PUBLIC_DOMAIN_RAILS}`

export const MYSOFIE_MONCOMPTE_URL = 'https://moncompte.mysofie.fr/user_session/new'

export const BENEFICIARIES_COUNT_MAX = 10

export const MYSOFIE_CALENDAR = 'https://calendly.com/mysofie_vente'

export const WEB_COOKIE = 'subscriptionId'

export enum PROSPECT_TRACKING_STATUS  {
  EMAIL_FILLED = 'EMAIL_FILLED',
  CONTRACT_NOT_SIGNED = 'CONTRACT_NOT_SIGNED',
  CONTRACT_SIGNED= 'CONTRACT_SIGNED',
  SUBSCRIBER_INFO_SAVED='SUBSCRIBER_INFO_SAVED'
}

export const GOOGLE_TAG_MANAGER_ID="GTM-K8JLVR7"
