import { createSlice } from '@reduxjs/toolkit'
import { fetchSubscriptionData } from '../../core/useCases/fetchSubscriptionData'
import { MyBoosterDiscount } from '../../server/apis/schemas/MyBoosterDiscount'

const initialState: MyBoosterDiscount = {
  preface: {
    banner: undefined,
    insert: undefined,
  },
  insert: undefined,
  cases: [],
  discountCodes: []
}

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionData.fulfilled, (_state, { payload }) => {
      return payload.discount
    })
  },
})

const defaultExports = { reducer: discountSlice.reducer }
export default defaultExports
