import { createAsyncThunk } from '@reduxjs/toolkit'
import { Extra } from '../../redux/store'
import { Subscription } from '../../types/Subscription'
import { CheckBeneficiaryParams } from '../../updateParams/CheckBeneficiaryParams'

export const checkBeneficiary = createAsyncThunk<Subscription, CheckBeneficiaryParams, Extra>(
  'subscription/checkBeneficiary',
  async (params, thunkApi) => {
    return thunkApi.extra.appApi.checkBeneficiary(params)
  }
)
