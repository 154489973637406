import Image from 'next/image'

type Props = {
  alt: string
  height: number
  src: { src: string } | string
  width: number
  className?: string
  onClick?: () => void
}

export default function Svg(props: Props) {
  // When importing an svg file from next, the src prop is an object with a src property.
  // When importing an svg file from storybook, the src prop is a string.
  const src = typeof props.src === 'string' ? props.src : props.src.src
  return (
    <Image
      role={props.onClick ? 'button' : 'img'}
      alt={props.alt}
      src={src}
      height={props.height}
      width={props.width}
      className={props.className}
      onClick={props.onClick}
    />
  )
}
