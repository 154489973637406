import { MessagesSendResponse } from '@mailchimp/mailchimp_transactional'
import { GetServerSidePropsContext } from 'next'
import zod from 'zod'
import { UniversignSignResponse } from '../../server/signature/parseUniversignResponse'
import { BaseProduct } from '../../types/BaseProduct'
import { CareNeeds } from '../../types/CareNeeds'
import { NewsletterPreferences } from '../../types/NewsletterPreferences'
import { Beneficiary } from '../../types/Person'
import { Subscription } from '../../types/Subscription'
import { SubscriptionWithDiscount } from '../../types/SubscriptionWithDiscounts'
import { CheckEmail } from '../../types/web/CheckEmail'
import { DevisEmail } from '../../types/web/DevisEmail'
import { ProspectTracking } from '../../types/web/ProspectTracking'
import { CheckBeneficiaryParams } from '../../updateParams/CheckBeneficiaryParams'
import { CheckSubscriberParams } from '../../updateParams/CheckSubscriberParams'
import { UpdateSubscriberParams } from '../../updateParams/updateSubscriberParams'
import { AppApiInterface, BankDetailsUpload } from './AppApiInterface'

export class AppApi implements AppApiInterface {
  id: string

  constructor(params: { context?: GetServerSidePropsContext; id: string }) {
    this.id = params.id
  }

  async fetchSubscriptionData(): Promise<SubscriptionWithDiscount> {
    const response = await fetch(`/api/subscriptions/${this.id}`)
    const json = await response.json()

    return json as SubscriptionWithDiscount
  }

  private getPostUrl(path: string): string {
    return `/api/subscriptions/${this.id}/${path}`
  }

  private async postSubscription(path: string, params?: string | object): Promise<Subscription> {
    const response = await fetch(this.getPostUrl(path), {
      method: 'POST',
      body: params ? JSON.stringify(params) : undefined,
    })

    if (!response.ok) {
      throw new Error(`The POST to ${path} failed: ${response.status} ${response.statusText}`)
    }

    return await this.parseResponse(response)
  }

  private async checkEmail(email: string): Promise<CheckEmail> {
    const response = await fetch(`/api/check-user-email?email=${encodeURIComponent(email)}`)

    if (!response.ok) {
      throw new Error(`Request failed: ${response.status} ${response.statusText}`)
    }

    return await response.json() as CheckEmail
  }

  private async trackProspects(params: ProspectTracking): Promise<void> {
    const response = await fetch('/api/track-prospects', {
      method: 'POST',
      body: params ? JSON.stringify(params) : undefined,
    })

    if (!response.ok) {
      throw new Error(`The POST to api track-prospects failed: ${response.status} ${response.statusText}`)
    }
    return await response.json()
  }

  private async sendDevisMail(params: DevisEmail): Promise<MessagesSendResponse[]> {
    const response = await fetch('/api/send-email', {
      method: 'POST',
      body: params ? JSON.stringify(params) : undefined,
    })

    if (!response.ok) {
      throw new Error(`The POST to api send email failed: ${response.status} ${response.statusText}`)
    }
    return await response.json() as MessagesSendResponse[]
  }



  private async parseResponse(response: Response): Promise<Subscription> {
    const json = await response.json()
    return json as Subscription
  }

  async checkBeneficiary(params: CheckBeneficiaryParams): Promise<Subscription> {
    return await this.postSubscription('check-beneficiary', params)
  }

  async checkSubscriber(params: CheckSubscriberParams): Promise<Subscription> {
    return await this.postSubscription('check-subscriber', params)
  }

  async updateCareNeeds(params: CareNeeds): Promise<Subscription> {
    return await this.postSubscription('care-needs', params)
  }

  async updateProductId(product: BaseProduct): Promise<Subscription> {
    return await this.postSubscription('product', { productId: product.id })
  }

  async updateDiscountCodes(discountCodes: string[]): Promise<Subscription> {
    return await this.postSubscription('discount-codes',  discountCodes )
  }
  async updateEffectiveDate(effectiveDateISO: string): Promise<Subscription> {
    return await this.postSubscription('effective-date', { effectiveDateISO })
  }

  async updateBankDetails(params: BankDetailsUpload): Promise<Subscription> {
    return await this.postSubscription('bank-details', { iban: params.iban })
  }

  async updateBeneficiary(beneficiary: Beneficiary): Promise<Subscription> {
    return await this.postSubscription('beneficiary', beneficiary)
  }

  async updateSubscriber(subscriber: UpdateSubscriberParams): Promise<Subscription> {
    return await this.postSubscription('subscriber', subscriber)
  }

  async checkUserEmail(email: string): Promise<CheckEmail> {
    return await this.checkEmail(email)
  }

  async prospectTracking(prospectTracking: ProspectTracking): Promise<void> {
    return await this.trackProspects(prospectTracking)
  }

  async sendDevisEmail(devisEmail: DevisEmail): Promise<MessagesSendResponse[]> {
    return await this.sendDevisMail(devisEmail)
  }

  async updateNewsletterPreferences(newsletterPreferences: NewsletterPreferences): Promise<Subscription> {
    return await this.postSubscription('newsletter-preferences', newsletterPreferences)
  }

  async generateAndSignDocuments(): Promise<UniversignSignResponse> {
    const response = await fetch(this.getPostUrl('generate-and-sign-documents'), {
      method: 'POST',
    })

    const json = await response.json()

    return zod.object({ iframeId: zod.string(), signatureId: zod.string() }).parse(json)
  }

  async updateSignatureId(signatureId: string): Promise<Subscription> {
    return await this.postSubscription('signature-id', { signatureId })
  }

  async updateBeneficiariesCount(params: { beneficiariesCount: number }): Promise<Subscription> {
    return await this.postSubscription('update-beneficiaries-count', params)
  }
}
